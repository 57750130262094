/**
 * @external L.Draggable
 * 
 * @see https://github.com/Leaflet/Leaflet/tree/v1.9.3/src/dom/Draggable.js
 */

L.Draggable.include({

    // updateMapBearing: function(mapBearing) {
    //     this._mapBearing = mapBearing;
    // },

});